import config from 'app-customs/config/config';

import { GOOGLE_MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';
import { isActive } from 'src/core/navigation/Router';
import * as Query from 'src/core/query/Query';
import showConfirmModal from 'src/core/util/showConfirmModal';

import { isAndroid, isIOS } from 'src/core/util/browser';

import { getBindedActions } from 'src/store/bindedActions';

import {
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  GOOGLE_MAP_LOADED,
  NAVIGATE,
  NAVIGATE_BACK,
  SHOW_ONE_POI_ON_GOOGLE_MAP_WITHOUT_NAVIGATION,
  CORDOVA_RESUME,
  REQUEST_LOCATION_CAPABILITIES,
} from 'src/store/actionTypes';

import { googleMapReload, googleMapShowPlace, googleMapReset } from 'src/store/actions';
import googleMapContext from './googleMapContext.js'; // to do to check

const LOG_PREF = '[GoogleMapMiddleware] ';

// let hasUserAPosition = false;

const queuedActions = [];
let mapReloadConfirmDisplayed = false;
let reloadOnPageChange = false;

const isReady = () => googleMapContext.isLoaded() && isActive(GOOGLE_MAP_PAGE_KEY);

export function requestLocation() {
  if (
    config.MAP &&
    config.MAP.GOOGLE_MAP &&
    config.MAP.GOOGLE_MAP.FEATURE_ENABLED &&
    config.MAP.GOOGLE_MAP.HAS_LOCATION_BUTTON &&
    cordova.plugins &&
    cordova.plugins.diagnostic
  ) {
    typeof cordova.plugins.diagnostic.requestLocationAuthorization === 'function' &&
      cordova.plugins.diagnostic.requestLocationAuthorization(() => {
        console.info('request Location GOOGLE_MAP Authorization was requested successfully.');
        checkLocation();
      }, function(error){
        console.error('request Location  GOOGLE_MAP error: ', error);
    });
  }
}

export function checkLocation() {
  if (
    config.MAP &&
    config.MAP.GOOGLE_MAP &&
    config.MAP.GOOGLE_MAP.FEATURE_ENABLED &&
    config.MAP.GOOGLE_MAP.HAS_LOCATION_BUTTON &&
    cordova.plugins &&
    cordova.plugins.diagnostic
  ) {
    typeof cordova.plugins.diagnostic.getLocationAuthorizationStatus === 'function' &&
      cordova.plugins.diagnostic.getLocationAuthorizationStatus(
        (status) => {
          const granted = [
            cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE,
            cordova.plugins.diagnostic.permissionStatus.GRANTED,
          ];
          if (granted.includes(status)) {
            getBindedActions().toggleLocationStatus(true);
            console.info(`${LOG_PREF}getLocationAuthorizationStatus OK: `, status);
          } else {
            getBindedActions().toggleLocationStatus(false);
            console.info(`${LOG_PREF}getLocationAuthorizationStatus KO: `, status);
          }
        },
        (error) => {
          getBindedActions().toggleLocationStatus(false);
          console.error(LOG_PREF, 'getLocationAuthorizationStatus KO error:', error);
        }
      );
    typeof cordova.plugins.diagnostic.isLocationEnabled === 'function' &&
      cordova.plugins.diagnostic.isLocationEnabled(
        function (enabled) {
          console.log(LOG_PREF, 'Location setting is ', enabled);
          if (enabled === true) {
            getBindedActions().toggleLocationCapabilityStatus(true);
          } else {
            getBindedActions().toggleLocationCapabilityStatus(false);
          }
        },
        function (error) {
          console.error(LOG_PREF, 'isLocationEnabled error', error);
          getBindedActions().toggleLocationCapabilityStatus(false);
        }
      );
  }
}
/**
 * @param {object} actions (store binded)
 */
export function addListeners() {
  if (
    config.MAP &&
    config.MAP.GOOGLE_MAP &&
    config.MAP.GOOGLE_MAP.FEATURE_ENABLED &&
    config.MAP.GOOGLE_MAP.HAS_LOCATION_BUTTON &&
    cordova.plugins &&
    cordova.plugins.diagnostic &&
    typeof cordova.plugins.diagnostic.registerLocationStateChangeHandler === 'function'
  ) {
    cordova.plugins.diagnostic.registerLocationStateChangeHandler(function (state) {
      console.info(`${LOG_PREF}registerLocationStateChangeHandler STATE: `, state);
      if (
        (isAndroid() && state !== cordova.plugins.diagnostic.locationMode.LOCATION_OFF) ||
        (isIOS() &&
          (state === cordova.plugins.diagnostic.permissionStatus.GRANTED ||
            state === cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE))
      ) {
        console.info(`${LOG_PREF}registerLocationStateChangeHandler OK: `, state);
        checkLocation();
      } else {
        console.info(`${LOG_PREF}registerLocationStateChangeHandler KO:`, state);
        getBindedActions().toggleLocationStatus(false);
      }
    });
  }
}

/**
 * Detect if assets update impacts on map
 * @param  {array} assets
 * @return {array}
 */
export const detectMapAssetUpdate = (assets) =>
  (assets || []).filter((path) => path.indexOf('files/maps/') !== -1);

/**
 * Detect if data update impacts on map
 * @param  {array} updated tables
 * @return {array}
 */
export function detectMapDataUpdate(tables) {
  if (!Array.isArray(tables)) {
    return [];
  }
  // 'all' corresponds with initial data loading on app startup
  if (tables.length === 1 && tables[0] === 'all') {
    return config.MAP.GOOGLE_MAP?.USED_TABLES;
  }
  return tables.filter((tableName) => config.MAP.GOOGLE_MAP?.USED_TABLES.indexOf(tableName) !== -1);
}

function _executeOrQueue(_func) {
  if (!isReady()) {
    // If Map page is not mounted yet, dispatch a navigate action to Map page
    // When GOOGLE_MAP_LOADED is broadcasted, empty actions queue
    queuedActions.push(_func);
  } else {
    _func();
  }

  window.setTimeout(redirectToMapIfNeeded, 80);
}

function executeQueuedActions() {
  if (isReady()) {
    while (queuedActions.length) {
      queuedActions.pop()();
    }
  }
}

function redirectToMapIfNeeded() {
  // Redirect to map page only if not already active
  if (isActive(GOOGLE_MAP_PAGE_KEY) !== true) {
    getBindedActions().navigate(GOOGLE_MAP_PAGE_KEY);
  }
}

/**
 * Check every POI before displaying them
 * @param  {array} pois
 * @param  {object} options (optional)
 * @param  {function} dispatch
 */
function _parseThenShowPOIs(pois, options, dispatch) {
  const entries = [];
  Object.keys(pois).forEach((dataType) => {
    // Is datatype displayable on MobiGeo ?
    // if (DISPLAYABLE_TYPES.indexOf(dataType) !== -1) {
    if (Array.isArray(pois[dataType]) && pois[dataType].length > 0) {
      pois[dataType].forEach((poiCriteria) => {
        if (poiCriteria) {
          let member;

          // Favorite position
          /* if (dataType === DATA_TYPE_FAVORITE_POSITIONS) {
            member = window.MobiGeo.Favorite.getAll().find((fav) => fav.id === poiCriteria.id);
          } else */
          if (typeof poiCriteria.id !== 'undefined') {
            member = Query.get(poiCriteria.id, dataType, ['places']);
          }
          // using client id (originalId) - case of push actions (e.g pushwoosh notification)
          else if (typeof poiCriteria.originalId !== 'undefined') {
            member = Query.find(
              [(item) => item.original_id === poiCriteria.originalId],
              dataType,
              { places: true }, // additional data to retrieve
              true
            ); // find one
          }
          /* if (member) {
            entries.push({
              id: dataType === DATA_TYPE_FAVORITE_POSITIONS ? member.id : member.original_id,
              type: dataType,
              placeId: poiCriteria.placeId, // (optional)
            });
          } */
        }
      });
    }
    // }
  });

  /* if (entries.length > 0) {
    _showPOI(entries, options, dispatch);
  } */
}

function proceedToReloadAfterDataAssetsUpdate() {
  getBindedActions().showNotification({
    message: getLabels().googleMap.reloadDueToUpdate,
    duration: 2, // sec
  });
  getBindedActions().googleMapReload();
}

function _askConfirmIfNeededBeforeRestart() {
  if (isActive(GOOGLE_MAP_PAGE_KEY) === false) {
    proceedToReloadAfterDataAssetsUpdate();

    // Skip confirmation
  } else if (config.MAP.GOOGLE_MAP.AUTO_RELOAD_AT_UPDATE) {
    proceedToReloadAfterDataAssetsUpdate();

    // Ask confirmation to reload the map now
  } else if (!mapReloadConfirmDisplayed) {
    mapReloadConfirmDisplayed = true;

    showConfirmModal({
      title: getLabels().googleMap.title,
      text: getLabels().googleMap.shouldReload,
      anywayCb() {
        mapReloadConfirmDisplayed = false;
      },
      yesCb() {
        proceedToReloadAfterDataAssetsUpdate();
      },
      noCb() {
        reloadOnPageChange = true;
      },
    });
  }
}

let lastNavigateBackTimestamp;

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case CORDOVA_RESUME:
        checkLocation();
        break;
      case HAS_NAVIGATED:
        let gMap = document.getElementById('g-map');
        let body = document.getElementsByTagName('body')[0];
        if (action.pageKey !== GOOGLE_MAP_PAGE_KEY) {
          gMap ? (gMap.style.display = 'none') : null;
          body.classList.remove('_gmaps_cdv_');
          body.removeAttribute('style', 'transform');
          dispatch(googleMapReset(false));
          break;
        } else if (action.pageKey === GOOGLE_MAP_PAGE_KEY) {
          gMap ? (gMap.style.display = 'block') : null;
          checkLocation();
          body.classList.add('_gmaps_cdv_');
          dispatch(googleMapReset(true));
        }
      // else fall through
      case GOOGLE_MAP_LOADED:
        window.setTimeout(executeQueuedActions, 50);
        break;

      case DATA_ASSETS_UPDATED:
        // Check if a reload is required
        if (googleMapContext.isNotLoaded() !== true) {
          const updatedTables = detectMapDataUpdate(action.tables);
          const updatedAssets = detectMapAssetUpdate(action.assets);

          if (updatedTables.length > 0) {
            console.info(`${LOG_PREF}Map data update detected: `, updatedTables);
          }
          if (updatedAssets.length > 0) {
            console.info(`${LOG_PREF}Map assets update detected: `, updatedAssets);
          }
          if (updatedTables.length > 0 || updatedAssets.length > 0) {
            window.setTimeout(_askConfirmIfNeededBeforeRestart, 2000);
          }
        }
        break;

      case NAVIGATE:
        if (
          action.pageKey === GOOGLE_MAP_PAGE_KEY &&
          action.options &&
          // Ignore if navigation comes from a 'back' (to not apply again POI options)
          (!lastNavigateBackTimestamp || new Date().getTime() - lastNavigateBackTimestamp > 300)
        ) {
          // Show one POI
          dispatch(googleMapShowPlace(action.options.poi.id));

          // Show several POIs
          if (action.options.pois) {
            _parseThenShowPOIs(action.options.pois, null, dispatch);
          }

          /*  // Show a favorite position
        if (action.options.favorite) {
          showFavoritePosition(action.options.favorite, dispatch);
        } */

          // Set custom POI styles on map
          /* if (action.options.customPoiState) {
          setCustomPoiStyle(action.options.customPoiState, dispatch);
        } */
        }

        // After an update involving map app-customs/assets (see above DATA_ASSETS_UPDATED), if user is on googleMapPage
        // then confirmation is asked for immediate map reload.
        //
        // If the user refuses:
        //  - `reloadOnPageChange` is set to true
        //  - on first page navigation, map is reloaded in background
        if (reloadOnPageChange) {
          reloadOnPageChange = false;
          dispatch(googleMapReload());
        }
        break;

      case NAVIGATE_BACK:
        lastNavigateBackTimestamp = new Date().getTime();

        if (reloadOnPageChange) {
          reloadOnPageChange = false;
          dispatch(googleMapReload());
        }
        break;

      case SHOW_ONE_POI_ON_GOOGLE_MAP_WITHOUT_NAVIGATION:
        action.options.poi &&
          _parseThenShowPOIs(
            {
              [action.options.poi.type]: [action.options.poi],
            },
            null,
            dispatch
          );
        break;

      case REQUEST_LOCATION_CAPABILITIES:
        console.log('REQUEST_LOCATION_CAPABILITIES googleMIDDLEWARE');
        requestLocation();
        break;

      /* case TOGGLE_FAVORITE:
      // User unchecked the favorite icon of a position on favorite page
      if (
        action.dataType === DATA_TYPE_FAVORITE_POSITIONS &&
        action.source !== GOOGLE_MAP_PAGE_KEY
      ) {
        if (action.isFav) {
          window.MobiGeo.Favorite.remove(parseInt(action.id, 10));
        } else {
          // Create/show
          showFavoritePosition(action.data, dispatch);
        }
      }
      break; */

      default:
    }
    return result;
  };
