module.exports = {
  "name": "ephj",
  "client": "Mobile spot",
  "description": "Mobile app for test",
  "validLangs": ["fr", "en"],
  "version": {
    "mainVersion": "5.0.0",
    "buildVersion": {
      "iOS": "102",
      "android": "106",
      "web": "76"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "11.0",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 31,
    "androidSigning": {
      "keyAlias": "mobilespotmom",
      "keyStorePassword": "jee1Uu0Hieloh7ba"
    },
    "app": "app-react",
    "name": {
      "default": "EPHJ_D"
    },
    "id": "com.mobilespot.ephj.dev",
    "appleTeamId": "2BAP3P29V2",
    "playStoreUrl": ""
  },
  "undeliveredFolders": ["source", "exports"],
  "crypto": false,
  "web": {
    "url": "ephj2024.ms-dev.mobile-spot.com"
  },
  "customFileUrlScheme": "msfile",
  "relatedRepos": [{
    "src": "git@bitbucket.org:mobilespotdev/cordova.git",
    "branch": "inte/ephj/main",
    "postCommands": ["cd cordova && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo.git",
    "branch": "inte/ephj/main",
    "postCommands": ["cd mobigeo && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo-customs-master.git",
    "branch": "inte/ephj/main",
    "dest": "mobigeo/mobigeo-customs"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/app-react.git",
    "branch": "inte/ephj/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/data-master.git",
    "branch": "inte/ephj/main",
    "dest": "app-react/app-customs",
    "postCommands": ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }]
};